import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { Typography } from '@aceandtate/ds';
import { paths } from 'paths';
import messages from 'messages/authentication';
import styled from 'styled-components';

const StyledPrivacyPolicyLink = styled(Link)`
  text-decoration: underline;
`;

export default function PrivacyPolicy() {
  return (
    <Typography variant='bodyS'>
      <FormattedMessage
        {...messages.privacyPolicyStatement}
        values={{
          privacyPolicy: (
            <StyledPrivacyPolicyLink href={paths.privacyPolicy} target='blank' rel='noreferrer noopener'>
              <FormattedMessage {...messages.privacyPolicy} />
            </StyledPrivacyPolicyLink>
          )
        }}
      />
    </Typography>
  );
}
