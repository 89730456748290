import { trackGaEvent } from '../helpers';
import { mapLine, mapProductSolidus, mappedCartContent } from '../utils';
import { Cart } from 'types/solidus';

export function addUpsellProducts({ sku, from }: { sku: string; from: 'cart' | 'pdp' }) {
  trackGaEvent({
    InteractionType: 'add to cart',
    event: 'upsellProducts',
    from,
    sku: sku
  });
}

export function changeCartQuantity({ sku }: { sku: string }) {
  trackGaEvent({
    InteractionType: 'change cart quantity',
    event: 'changeQuantity',
    sku: sku
  });
}

export function removeFromCart(lines: any[], newCart: Cart, isHto?: boolean) {
  const productsRemoved = lines.map(line => ({
    ...mapProductSolidus(line.variant, line.product_type),
    ...mapLine(line)
  }));

  const checkoutType = isHto ? 'Try' : 'Buy';

  trackGaEvent({
    ecommerce: {
      currencyCode: newCart.currency,
      remove: {
        products: productsRemoved
      }
    },
    event: 'removeFromCart',
    ...mappedCartContent(newCart),
    checkoutType
  });
}

export function addBackToCart({ sku }: { sku: string }) {
  trackGaEvent({
    InteractionType: 'add back to cart',
    event: 'addBackToCart',
    sku: sku
  });
}

export function addToCart(lines: any[], newCart: Cart) {
  const productsAdded = lines.map(line => ({
    ...mapProductSolidus(line.variant, line.product_type),
    ...mapLine(line)
  }));

  trackGaEvent({
    cart_token: newCart.guest_token,
    ecommerce: {
      add: {
        products: productsAdded
      },
      currencyCode: newCart.currency
    },
    event: 'addToCart',
    ...mappedCartContent(newCart)
  });
}

export function repeatOrder() {
  trackGaEvent({
    event: 'repeatOrder'
  });
}

export function repeatOrderError() {
  trackGaEvent({
    event: 'repeatOrderError',
    lensType: 'Computer Lenses'
  });
}
