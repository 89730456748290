import React, { useContext } from 'react';
import { ServicesContext } from 'services/context';
import { FormattedMessage } from 'react-intl';
import { language as languageMessages } from 'messages/intl';
import { useChangeRegion } from 'paths';
import * as Styles from './styles';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover/Popover';
import { Button, Typography } from '@aceandtate/ds';
import { brandColors } from 'styles';

type Props = {
  id?: string;
};

export default function LanguagePicker({ id }: Props) {
  const context = useContext(ServicesContext);
  const changeRegion = useChangeRegion();

  if (context.webStore.languages.length > 1) {
    return (
      <Popover modal>
        <PopoverTrigger asChild>
          <Button variant='link' color='light' data-testid={id}>
            <FormattedMessage {...languageMessages[context.locale.lang || 'nl']} />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <nav style={{ paddingLeft: 0 }}>
            {context.webStore.languages.map(lang => (
              <Styles.Option key={lang} data-testid={lang} onClick={() => changeRegion(undefined, lang)} role='option'>
                <Typography color={brandColors.black}>
                  <FormattedMessage {...languageMessages[lang]} />
                </Typography>
              </Styles.Option>
            ))}
          </nav>
        </PopoverContent>
      </Popover>
    );
  } else {
    return null;
  }
}
