import { Typography } from '@aceandtate/ds';
import { usePath } from 'paths';
import React from 'react';
import { footerEvents } from 'tracking';
import { SubLink } from '../../types';
import * as styles from './styles';
import { Popover, PopoverTrigger, PopoverContent } from 'components/Popover/Popover';

interface Props {
  id: string;
  text: string;
  href: string;
  subLinks?: SubLink[];
}

export default function FooterPopover({ text, subLinks }: Props) {
  const pathTo = usePath();

  return (
    <styles.FooterLinkWrapper>
      <Popover>
        <PopoverTrigger>
          <Typography variant='h4'>{text}</Typography>
        </PopoverTrigger>
        {subLinks && (
          <PopoverContent>
            <styles.FooterLinkPopover>
              {subLinks.map((subLink, index) => {
                const { href, text, urlRoute } = subLink;
                const linkHref = urlRoute?.path ? urlRoute.path : href;
                return (
                  <a
                    href={pathTo(linkHref)}
                    key={index}
                    aria-label={text}
                    onClick={() => {
                      footerEvents.footerInteraction(linkHref.replace(/^\/+/, ''));
                    }}
                  >
                    <Typography>{text}</Typography>
                  </a>
                );
              })}
            </styles.FooterLinkPopover>
          </PopoverContent>
        )}
      </Popover>
    </styles.FooterLinkWrapper>
  );
}
