import React, { useContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { usePath, paths } from 'paths';
import FullscreenLoader from 'components/Loaders/FullscreenLoader';
import { useUserService, useUserState } from 'services/userService';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import { Button } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import ecommerceMessages from 'messages/ecommerce';
import messages from 'messages/authentication';
import { User } from 'types/solidus';
type Props = {
  children:
    | (({ logout, userProfile }: { logout: (path?: string) => void; userProfile: User }) => React.ReactNode)
    | React.ReactNode;
};

export default function ProtectedRoute(props: Props) {
  const { children } = props;
  const router = useRouter();
  const pathTo = usePath();
  const { isUserLoggedIn, isValidating, userProfile } = useUserState();
  const notifications = useContext(NotificationsContext.Context);
  const wasUserLoggedIn = useRef(null);
  const isLoggingOut = useRef(false);
  const { logoutUser } = useUserService();

  useEffect(() => {
    if (!isValidating && !isUserLoggedIn) {
      // Only show the notification if the user is not logging out intentionally
      if (wasUserLoggedIn.current && !isLoggingOut.current) {
        notifications.addNotification({
          action: () => (
            <Button color='dark' href={pathTo(paths.login)}>
              <FormattedMessage {...ecommerceMessages.continue} />
            </Button>
          ),
          children: <FormattedMessage {...messages.sessionExpiredDescription} />,
          isModal: true,
          name: 'session-expired-notification',
          sticky: true,
          title: <FormattedMessage {...messages.sessionExpiredTitle} />
        });
        return;
      }
      router.replace({
        pathname: pathTo(paths.login),
        query: {
          redirect: encodeURI(router.asPath)
        }
      });
    }
    if (!isValidating) {
      wasUserLoggedIn.current = isUserLoggedIn;
    }
  }, [isValidating, isUserLoggedIn]);

  // Logout using this function so you don't show the notification when logging out intentionally
  const logout = (path?: string) => {
    isLoggingOut.current = true;
    logoutUser(path);
  };

  if (isUserLoggedIn) {
    return (
      <>
        {typeof children === 'function'
          ? children({
              logout,
              userProfile
            })
          : children}
      </>
    );
  }

  return <FullscreenLoader />;
}
