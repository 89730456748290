import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';
import { Checkbox, Flex, InputControl, TextInput, grid } from '@aceandtate/ds';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import formLabelMessages from 'messages/formLabels';

export const StyledPrivacyPolicyLink = styled(Link)`
  text-decoration: underline;
`;

type PrivacyPolicyCheckboxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  id?: string;
};

function PrivacyPolicyCheckbox({ checked, onChange, label, children, style, id }: PrivacyPolicyCheckboxProps) {
  return (
    <Flex flexDirection='column' style={style} gap={grid[12]}>
      <Checkbox checked={checked} onCheckedChange={onChange} data-testid='newsletter' label={label} id={id} />
      {checked && children}
    </Flex>
  );
}

const DateOfBirth = React.forwardRef<HTMLInputElement>((props, ref) => {
  return (
    <InputControl id='dob'>
      <InputControl.Label>
        <FormattedMessage {...formLabelMessages.dateOfBirth} /> (<FormattedMessage {...formLabelMessages.optional} />)
      </InputControl.Label>
      <InputMask {...props} mask='99-99-9999' maskChar='_'>
        {(({ ...inputProps }) => <TextInput placeholder='DD-MM-YYYY' {...inputProps} ref={ref} />) as any}
      </InputMask>
    </InputControl>
  );
});

export default Object.assign(PrivacyPolicyCheckbox, {
  DateOfBirth
});
