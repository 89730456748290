import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Typography, Button, Modal, InputControl, TextInput, Checkbox } from '@aceandtate/ds';
import PrivacyPolicy from 'components/PrivacyPolicy';
import { useSubscribe } from 'services/newsletterService';
import { useSubscribeToStockMailingList } from 'services/stockMailingListSubscriptionService';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { stockEvents } from 'tracking';
import newsletterFormMessages from 'messages/newsletterForm';
import messages from './messages';
import * as Styles from './styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  sku: string;
};

export default function OOSModal(props: Props) {
  const { onClose, sku, isOpen } = props;
  const [newsletterSubscribed, setNewsletterSubscribed] = useState(false);

  const subscribeToStockMailingList = useSubscribeToStockMailingList();
  const subscribeToNewsLetter = useSubscribe();
  const validationOptions = useValidationOptions();
  const { handleSubmit, setError, register, formState, getValues } = useForm<{ 'oosModal-email': string }>({
    mode: 'onChange'
  });
  const { errors } = formState;

  async function onSubmit() {
    const email = getValues('oosModal-email');
    try {
      await subscribeToStockMailingList({ email, sku });
      stockEvents.backInStockFormSubmitted({ sku });
      if (newsletterSubscribed) {
        await subscribeToNewsLetter({ email, formType: 'OOS modal' });
      }
      onClose();
    } catch (error) {
      setError('oosModal-email', { message: error.message });
    }
  }

  function handleNewsletterSubscribe() {
    setNewsletterSubscribed(!newsletterSubscribed);
  }

  function renderValidation(key: string) {
    return errors?.[key] && <InputControl.Validation status='error'>{errors[key].message}</InputControl.Validation>;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      content={
        <>
          <Typography variant='h4' gutterBottom>
            <FormattedMessage {...messages.oosModalTitle} />
          </Typography>
          <Typography variant='bodyM' gutterBottom>
            <FormattedMessage {...messages.oosModalBody} />
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Styles.FormGroup>
              <Styles.StyledInputControl id='oosModal-email' required>
                <InputControl.Label>
                  <FormattedMessage {...messages.emailLabel} />
                </InputControl.Label>
                <TextInput
                  {...register('oosModal-email', { ...validationOptions.emailValidation })}
                  type='email'
                  fullWidth
                  placeholder='john@doe.com'
                  data-testid='oos-modal'
                />
                {renderValidation('oosModal-email')}
              </Styles.StyledInputControl>

              <Checkbox
                checked={newsletterSubscribed}
                onCheckedChange={handleNewsletterSubscribe}
                id='newsletter'
                label={
                  <Typography variant='bodyS'>
                    <FormattedMessage {...newsletterFormMessages.subscribeToNewsletter} />
                  </Typography>
                }
              />
            </Styles.FormGroup>
            <Styles.PrivacyPolicyContainer>
              <PrivacyPolicy />
            </Styles.PrivacyPolicyContainer>
            <Button color='primary' fullWidth disabled={!formState.isValid}>
              <FormattedMessage {...messages.oosModalCta} />
            </Button>
          </form>
        </>
      }
    />
  );
}
