import { FormPrescriptionType, prescriptionTypes } from 'globalConstants';
import { PRODUCT_TAXONS } from 'globalConstants/productTaxonomies';
import { FrameLine, Line } from 'types/solidus';
import { LENS_SURCHARGE_TO_CUSTOM_MAP } from 'utils/frameConfigurations';
import { isSkuSunny } from 'utils/product/productHelpers';
import frameLimits from 'config/frameLimits';
export function isPrescriptionLine(line: Line) {
  return line.prescription_type && line.prescription_type !== 'plano';
}

export function filterLinesWithPrescription(lines: Line[]) {
  return lines.filter(isPrescriptionLine);
}

export function findFirstLineWithPrescription(lines: Line[]) {
  return lines.find(isPrescriptionLine);
}

export function hasPrescriptionLines(lines: Line[]) {
  return lines.some(isPrescriptionLine);
}

export function transformLineToOlcCustomizations(line: Line) {
  const customizations = [];

  if (line.lens_upgrade_type) {
    customizations.push(LENS_SURCHARGE_TO_CUSTOM_MAP[line.lens_upgrade_type]);
  }

  if (line.premium_lenses) {
    customizations.push('premium_lenses');
  }

  if (line.lens_color === 'UV420') {
    customizations.push('lens_color_blf');
  }

  if (line.lens_color === 'ATGREY01TR') {
    customizations.push('lens_color_photochromic');
  }
  if (line.polarised) {
    customizations.push('polarised_lenses');
  }

  return customizations;
}

export function transformLineToOlcLensType(line: Line, customizations, prescriptionId: string) {
  const hasOtherLenses = customizations.some(c =>
    ['sunny_pro_lenses', 'premium_basic_lenses', 'premium_plus_lenses', 'premium_lenses'].includes(c.customizationKey)
  );

  const showEssentialLenses =
    hasOtherLenses &&
    (['multifocal', 'single_vision', 'relax_5_lenses'].includes(prescriptionId) ||
      (prescriptionId === 'plano' && isSkuSunny(line.variant.sku)));

  const essentialLensType = line.lens_color === 'sunny' ? 'sunny_essential_lenses' : 'essential_lenses';

  return LENS_SURCHARGE_TO_CUSTOM_MAP[line.lens_upgrade_type] || (showEssentialLenses && essentialLensType) || null;
}

export function getPrescriptionIdFromLine(line: Line): string {
  if (line.lenses_production_type === 'relax_5_lenses') {
    return 'relax_5_lenses';
  }

  return line.prescription_type;
}

export function getFormPrescriptionTypeFromLine(line: Line) {
  const formPrescriptionTypes = Object.keys(prescriptionTypes) as FormPrescriptionType[];

  if (formPrescriptionTypes.includes(line.lenses_production_type as any)) {
    type IntersectionType = FormPrescriptionType & Line['lenses_production_type'];
    return line.lenses_production_type as IntersectionType;
  }

  type ExclusionType = Exclude<Line['prescription_type'], (typeof nonPrescriptionTypes)[number]>;
  const nonPrescriptionTypes = ['upload', 'will_send_later', 'plano'] as const;
  if (nonPrescriptionTypes.includes(line.prescription_type as any)) return undefined;

  return line.prescription_type as ExclusionType;
}

export function isArchiveSaleLine(line: Line) {
  return line.variant.taxons?.includes(PRODUCT_TAXONS.ARCHIVE_SALE);
}

export function canEditOrderLine(line: Line): line is FrameLine {
  return line.product_type === 'frame' && !line.variant.off_the_shelf && !isArchiveSaleLine(line);
}

export function isPrescriptionLimitLine(line: Line) {
  return Object.keys(frameLimits).includes(line.variant.sku);
}
