import React, { useContext, useEffect, useState } from 'react';
import { Flex, Toast, Typography, grid } from '@aceandtate/ds';
import * as Sentry from '@sentry/nextjs';
import { useCartService, useCartState } from 'services/cartService';
import { ServicesContext } from 'services/context';
import { customizePaymentMethods, useGetPaymentMethods } from 'services/adyenService';
import { Line, PrescriptionType } from 'types/solidus';
import { NON_PLANO_PRESCRIPTIONS } from 'globalConstants';
import { duplicateOrderLine, isSale } from 'utils/helpers/cart';
import NotificationsContext from 'features/Notifications/NotificationsContext';
import ConfiguratorCartItem from './ConfiguratorCartItem';
import { cartEvents } from 'tracking';
import { FormattedMessage } from 'react-intl';
import ShippingInfo from 'components/ShippingInfo';
import PaymentIcon from 'components/PaymentIcon';
import CountryPicker from 'components/CountryPicker';
import FormattedPrice from 'components/DisplayLabels/FormattedPrice';
import cartMessages from 'messages/cart';
import globalErrorMessages from 'messages/globalErrors';
import * as Styles from '../styles';
import { useConfiguratorStore } from '../configuratorStore';

export default function ConfiguratorCart() {
  const { methods } = useConfiguratorStore();
  const { setLoading } = methods;
  const [paymentOptions, setPaymentOptions] = useState([]);

  const { getCheckoutPaymentMethods } = useGetPaymentMethods();
  const { webStore } = useContext(ServicesContext);
  const cartService = useCartService();
  const cartQuery = useCartState();
  const { cart } = cartQuery.data;

  const hasActiveSaleItems = isSale(cart, webStore);

  const regularLines = cart.regular_lines;
  const { currency, hasFreeReturns } = webStore.config;
  const cartPrescriptionTypes = regularLines.map((product: Line) => product.prescription_type);
  const askForPrescriptionLater = NON_PLANO_PRESCRIPTIONS.some((type: PrescriptionType) =>
    cartPrescriptionTypes.includes(type)
  );
  const notifications = useContext(NotificationsContext.Context);

  useEffect(() => {
    async function getMethods() {
      const { data, success } = await getCheckoutPaymentMethods(cart.total);
      if (success) {
        const dataCustomized = customizePaymentMethods(data, cart.regular_lines);
        const paymentMethodsMapped = dataCustomized.paymentMethods
          .map(i => (i.type === 'scheme' ? ['mc', 'visa'] : i.type))
          .flat();
        setPaymentOptions(paymentMethodsMapped);
      }
    }
    if (cart?.regular_lines?.length > 0) {
      getMethods();
    }
  }, [cart]);

  const frameError = cartErrorResponse => {
    notifications.addNotification({
      children: <FormattedMessage {...globalErrorMessages.apiRequestError} />,
      name: 'error-frame-notification',
      sticky: false,
      variant: 'error'
    });

    const err = new Error(
      `An unexpected error occurred removing or fetching items in the cart. ${JSON.stringify(cartErrorResponse)}`
    );
    Sentry.captureException(err);
    return false;
  };

  const removeProductFromCart = async (line: Line) => {
    setLoading(true);
    const removeFromCartResponse = await cartService.removeFromCart(line);
    setLoading(false);

    const backToCart = async closeNotification => {
      const addToCartResponse = await cartService.addToCart({
        lens_color: line.lens_color ?? null,
        lenses_production_type: line.lenses_production_type ?? null,
        lens_upgrade_type: line.lens_upgrade_type ?? null,
        polarised: line.polarised ?? null,
        premium_lenses: line.premium_lenses ?? null,
        prescription_type: line.prescription_type,
        quantity: line.quantity,
        variant_id: line.variant.id
      });
      if (addToCartResponse.success) {
        cartEvents.addBackToCart({ sku: line.variant.sku });
        closeNotification();
      }
    };

    if (removeFromCartResponse.success) {
      notifications.addNotification({
        action: (closeNotification: () => void) => (
          <Toast.Action altText='undo' onClick={() => backToCart(closeNotification)}>
            <FormattedMessage {...cartMessages.undo} />
          </Toast.Action>
        ),
        children: <FormattedMessage {...cartMessages.productRemovedSnackbar} />,
        name: 'product-removed-notification'
      });
    } else {
      frameError(removeFromCartResponse.error);
    }
  };

  async function duplicateProductToCart(line: Line) {
    setLoading(true);
    const addToCartResponse = await cartService.addToCart(duplicateOrderLine(line));
    setLoading(false);

    if (addToCartResponse.error) {
      frameError(addToCartResponse.error);
    }
  }

  // Empty cart
  if (!cartQuery.isValidating && regularLines.length < 1) {
    return (
      <div>
        <Typography variant='bodyM'>
          <FormattedMessage {...cartMessages.emptyCartMessage} />
        </Typography>
      </div>
    );
  }

  return (
    <Flex flexDirection='column' gap={grid[24]} data-testid='configurator-cart'>
      {askForPrescriptionLater && (
        <Typography variant='bodyM'>
          <FormattedMessage {...cartMessages.askForPrescriptionLater} />
        </Typography>
      )}
      <Flex flexDirection='column' gap={grid[24]}>
        {cart.regular_lines &&
          cart.regular_lines.map(line => (
            <ConfiguratorCartItem
              key={line.id}
              type='regular'
              line={line}
              currency={currency}
              removeFromCart={removeProductFromCart}
              duplicateOrderLine={duplicateProductToCart}
              hasActiveSale={hasActiveSaleItems}
            />
          ))}
      </Flex>
      {cart.store_credit > 0 && (
        <Styles.PromotionsBlock>
          <Typography fontWeight='regular'>
            Store Credit (<FormattedPrice currency={cart.currency} price={cart.store_credit} />)
          </Typography>
          <Typography fontWeight='regular'>
            <FormattedPrice
              currency={cart.currency}
              price={-1 * Math.min(cart.store_credit, cart.item_total + cart.adjustment_total)}
            />
          </Typography>
        </Styles.PromotionsBlock>
      )}

      {cart.regular_lines && cart.regular_lines.length > 0 && (
        <Styles.PaymentBlock>
          <ShippingInfo
            hasFreeShipping={cart.ship_total === 0}
            link={<CountryPicker id='cartHto.country-picker' hasFlag />}
            showReturnPolicy
            hasFreeReturns={hasFreeReturns}
            isoCode={webStore.country.iso}
          />
          <Flex style={{ flexFlow: 'wrap' }}>
            {paymentOptions && paymentOptions.map(option => <PaymentIcon name={option} key={option} />)}
          </Flex>
        </Styles.PaymentBlock>
      )}
    </Flex>
  );
}
