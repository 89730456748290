import {
  brandColors,
  breakpointRules,
  Button,
  Flex,
  grid,
  IconBin,
  IconClose,
  IconDuplicate,
  IconEdit,
  Typography,
  uiColors,
  useMediaQuery
} from '@aceandtate/ds';
import Link from 'components/Link';
import PriceLabel from 'components/DisplayLabels/PriceLabel';
import Visibility from 'components/Visibility';
import { frameConfig } from 'globalConstants';
import React, { useState } from 'react';
import { useCartService } from 'services/cartService';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePath } from 'paths';
import { format } from 'date-fns';
import { transform } from 'utils/images';
import { FrameLine, GiftcardLine, Line } from 'types/solidus';
import { getOptimizedZoomedInProductImageUrl } from 'utils/cloudinary';
import giftcardMessages from 'messages/giftcard';
import prescriptionMessages from 'messages/prescription';
import lensesMessages from 'messages/opticalLenses';
import cartMessages from 'messages/cart';
import productFeatureMessages from 'messages/productFeatures';
import ecommerceMessages from 'messages/ecommerce';
import * as Styles from './styles';
import GiftcardConfigurator from 'components/Configurators/GiftcardConfigurator/GiftcardConfigurator';
import { VariableGiftcardParams } from 'components/Configurators/GiftcardConfigurator/types';
import Image from 'next/image';
import { useConfiguratorStore } from '../../configuratorStore';
import { getImageTitleForProduct } from 'views/ProductCollection/helper';
import { canEditOrderLine } from 'utils/cartLine';

type Props = {
  currency: string;
  line: Line;
  type: 'regular' | 'home-try-on';
  hasActiveSale: boolean;
  removeFromCart: (line: Line, isHto?: boolean) => void;
  duplicateOrderLine: (line: Line) => void;
};

const isVariableGiftcard = (line: Line): line is GiftcardLine => line.variant.sku === 'giftcard-variable-price';
const isFrame = (line: Line): line is FrameLine => line.product_type === 'frame';

export default function ConfiguratorCartItem(props: Props) {
  const { currency, line, type, hasActiveSale, removeFromCart, duplicateOrderLine } = props;
  const { methods } = useConfiguratorStore();
  const [isGiftcardDrawerOpen, setIsGiftcardDrawerOpen] = useState(false);
  const isLaptop = useMediaQuery(breakpointRules.laptop);

  const pathTo = usePath();
  const cartService = useCartService();
  const intl = useIntl();

  function renderDescription() {
    const {
      variant: { color, sku },
      product_type,
      price
    } = line;

    if (isVariableGiftcard(line)) {
      const isPersonalised = line.gift_card_personalisation;
      const { recipient_name, recipient_email, delivery_date } = line.gift_card_personalisation || {};
      const date = delivery_date && new Date(delivery_date);
      return (
        <>
          <GiftcardConfigurator
            onDuplicate={() => cartService.addToCart([duplicateOrderLine(line)])}
            defaultMode={isPersonalised ? 'view' : 'edit'}
            drawerProps={{ onClose: () => setIsGiftcardDrawerOpen(false), open: isGiftcardDrawerOpen }}
            selectedPrice={line.price}
            line={line}
            onSubmit={async (data: VariableGiftcardParams) => {
              const { gift_card_personalisation, user_entered_price } = data;
              return cartService.updateCartLine({
                new: { ...line, gift_card_personalisation, user_entered_price },
                original: line
              });
            }}
          />
          {isPersonalised && (
            <>
              <Typography variant='bodyM' data-testid='giftcard.recipient-name'>
                {recipient_name}
              </Typography>
              <Typography variant='bodyM' data-testid='giftcard.recipient-email'>
                {recipient_email}
              </Typography>
              <Typography variant='bodyM' data-testid='giftcard.delivery-date'>
                {date && format(date, 'yyyy-MM-dd')}
              </Typography>
            </>
          )}
          {isPersonalised ? (
            <Button
              type='button'
              variant='link'
              onClick={() => setIsGiftcardDrawerOpen(true)}
              data-testid='giftcard.view-personalisation'
              style={{ textAlign: 'left' }}
            >
              <FormattedMessage {...giftcardMessages.viewPersonalisationButton} />
            </Button>
          ) : (
            <Button
              type='button'
              variant='link'
              size='small'
              onClick={() => setIsGiftcardDrawerOpen(true)}
              data-testid='giftcard.add-personalisation'
              style={{ textAlign: 'left' }}
            >
              <FormattedMessage {...giftcardMessages.addPersonalisationButton} />
            </Button>
          )}
        </>
      );
    } else if (product_type === 'giftcard') {
      const messageId = sku.replace(/-/g, '');

      const giftCardPrice = intl.formatNumber(price, {
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency'
      });

      return (
        <Typography variant='bodyM'>
          {giftcardMessages[messageId]
            ? intl.formatMessage(giftcardMessages[messageId], {
                price: giftCardPrice
              })
            : giftCardPrice}
        </Typography>
      );
    }
    return (
      <Typography variant='bodyS' color={brandColors.dark75}>
        {color}
      </Typography>
    );
  }

  const lensColorMessage =
    frameConfig[type].allowsLensColor &&
    line.lens_color &&
    line.lens_color !== 'sunny' &&
    lensesMessages[line.lens_color];

  const prescriptionTypeMessage =
    line.product_type === 'frame' &&
    frameConfig[type].showPrescriptionType &&
    prescriptionMessages[line.prescription_type];

  const available = line.variant.in_stock;

  const image = transform(line.variant.image_url, {
    height: 250,
    quality: 100,
    width: 250
  });

  const hasPriceAdjustment = line.total !== line.price * line.quantity;
  const isNosepadVariant = line.variant?.sku.includes('-nose-pad');
  return (
    <Styles.CartItemContainer>
      <Styles.ItemProduct>
        <Styles.DisplayData>
          {image && (
            <Link href={pathTo(`/${line.variant.sku}`)} style={{ display: 'flex' }}>
              <Image
                src={getOptimizedZoomedInProductImageUrl({ type: line.product_type, url: image })}
                width={isLaptop ? 170 : 115}
                height={isLaptop ? 170 : 115}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'cover'
                }}
                alt={getImageTitleForProduct(intl, line.variant.color, line.variant.name)}
              />
            </Link>
          )}
          <Styles.ProductDetails>
            <Flex justifyContent='space-between'>
              <Styles.ItemName>
                <Link href={pathTo(`/${line.variant.sku}`)}>
                  <Typography variant='h5'>
                    {isVariableGiftcard(line) ? intl.formatMessage(giftcardMessages.productTitle) : line.variant.name}
                  </Typography>
                </Link>
                {renderDescription()}
              </Styles.ItemName>
              <Visibility visibleOn={['mobile']} displayAs='flex' style={{ width: 'auto' }}>
                <Styles.ItemPrice>
                  <Typography variant='h5'>
                    {line.quantity > 1 && (
                      <>
                        <span>{line.quantity} ×</span>
                        &nbsp;
                      </>
                    )}
                    <PriceLabel price={line.price} currency={currency} isBeforeSalePrice={hasPriceAdjustment} />
                    {hasPriceAdjustment && (
                      <>
                        &nbsp;
                        <PriceLabel
                          price={line.total / line.quantity}
                          currency={currency}
                          isSale={hasPriceAdjustment && hasActiveSale}
                          isSpecialPrice={hasPriceAdjustment && !hasActiveSale}
                        />
                      </>
                    )}
                  </Typography>
                </Styles.ItemPrice>
              </Visibility>
              <Visibility
                visibleOn={['tablet', 'laptop', 'desktop']}
                style={{
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: 'auto'
                }}
                displayAs='flex'
              >
                {removeFromCart && (
                  <Styles.ActionButton
                    variant='link'
                    size='small'
                    onClick={() => removeFromCart(line)}
                    data-testid={`remove-item-${line.variant.sku}`}
                  >
                    <IconClose />
                  </Styles.ActionButton>
                )}
              </Visibility>
            </Flex>
            <Styles.ItemAddOns>
              {isNosepadVariant && available && (
                <Typography variant='bodyM'>
                  + <FormattedMessage {...productFeatureMessages.nosepads} />
                </Typography>
              )}
              {prescriptionTypeMessage && available && (
                <Typography variant='bodyM'>
                  + <FormattedMessage {...prescriptionTypeMessage} />
                </Typography>
              )}
              {line.lenses_production_type === 'relax_5_lenses' && available && (
                <Typography variant='bodyM'>
                  + <FormattedMessage {...prescriptionMessages.relax_lenses} />
                </Typography>
              )}
              {lensColorMessage && available && (
                <Typography variant='bodyM'>
                  + <FormattedMessage {...lensColorMessage} />
                </Typography>
              )}
              {/* can be removed after a couple of months, after all carts phase out old premium lenses */}
              {line.premium_lenses && available && (
                <Typography variant='bodyM'>
                  + <FormattedMessage {...lensesMessages.premium} />
                </Typography>
              )}
              {line.lens_upgrade_type && available && (
                <Typography variant='bodyM'>
                  + <FormattedMessage {...(lensesMessages[line.lens_upgrade_type] ?? lensesMessages.undefined)} />
                </Typography>
              )}
              {line.polarised && available && (
                <Typography variant='bodyM'>
                  + <FormattedMessage {...lensesMessages.polarised} />
                </Typography>
              )}
              {!available && (
                <Typography variant='bodyM' color={uiColors.error}>
                  <FormattedMessage {...cartMessages.outOfStock} />
                </Typography>
              )}
            </Styles.ItemAddOns>
            <Visibility
              visibleOn={['tablet', 'laptop', 'desktop']}
              style={{ flexDirection: 'row', justifyContent: 'space-between', width: 'auto' }}
              displayAs='flex'
            >
              <Styles.ItemPrice>
                <Typography variant='h6'>
                  {line.quantity > 1 && (
                    <>
                      <span>{line.quantity} ×</span>
                      &nbsp;
                    </>
                  )}
                  <PriceLabel price={line.price} currency={currency} isBeforeSalePrice={hasPriceAdjustment} />
                  {hasPriceAdjustment && (
                    <>
                      &nbsp;
                      <PriceLabel
                        price={line.total / line.quantity}
                        currency={currency}
                        isSale={hasPriceAdjustment && hasActiveSale}
                        isSpecialPrice={hasPriceAdjustment && !hasActiveSale}
                      />
                    </>
                  )}
                </Typography>
              </Styles.ItemPrice>
            </Visibility>
            <Visibility
              visibleOn={['tablet', 'laptop', 'desktop']}
              style={{
                alignItems: 'flex-end',
                flexDirection: 'row',
                gap: grid[12],
                justifyContent: 'flex-end',
                width: 'auto'
              }}
              displayAs='flex'
            >
              <Styles.DisplayAction>
                {isFrame(line) && (
                  <Styles.ActionButton
                    onClick={() => cartService.addToCart([duplicateOrderLine(line)])}
                    variant='link'
                    size='small'
                  >
                    <IconDuplicate />
                    <FormattedMessage {...cartMessages.duplicateItem} />
                  </Styles.ActionButton>
                )}
              </Styles.DisplayAction>
              <Styles.DisplayAction>
                {canEditOrderLine(line) && (
                  <Styles.ActionButton onClick={() => methods.startEditFromLine(line)} variant='link' size='small'>
                    <IconEdit />
                    <FormattedMessage {...ecommerceMessages.edit} />
                  </Styles.ActionButton>
                )}
              </Styles.DisplayAction>
            </Visibility>
          </Styles.ProductDetails>
        </Styles.DisplayData>
      </Styles.ItemProduct>
      <Visibility visibleOn={['mobile']} style={{ width: 'auto' }} displayAs='flex'>
        <Styles.DisplayAction style={{ fontSize: '12px' }}>
          {isFrame(line) && (
            <Styles.ActionButton
              onClick={() => cartService.addToCart([duplicateOrderLine(line)])}
              variant='link'
              size='small'
            >
              <IconDuplicate />
              <FormattedMessage {...cartMessages.duplicateItem} />
            </Styles.ActionButton>
          )}
          {canEditOrderLine(line) && (
            <Styles.ActionButton onClick={() => methods.startEditFromLine(line)} variant='link' size='small'>
              <IconEdit />
              <FormattedMessage {...ecommerceMessages.edit} />
            </Styles.ActionButton>
          )}
          {removeFromCart && (
            <Styles.ActionButton
              variant='link'
              size='small'
              onClick={() => removeFromCart(line)}
              style={{ marginLeft: 'auto' }}
              data-testid={`remove-item-${line.variant.sku}`}
            >
              <IconBin />
              <FormattedMessage {...cartMessages.removeItem} />
            </Styles.ActionButton>
          )}
        </Styles.DisplayAction>
      </Visibility>
    </Styles.CartItemContainer>
  );
}
