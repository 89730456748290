import { brandColors, grid } from '@aceandtate/ds';
import styled from 'styled-components';

export const Option = styled.button`
  padding: ${grid[12]} ${grid[12]};
  cursor: pointer;
  display: block;
  width: 100%;
  && {
    align-items: flex-start;
  }

  :focus-visible {
    outline: none;
    background: ${brandColors.dark10};
  }

  :hover {
    background: ${brandColors.dark10};
  }
`;
