import React, { ReactElement, useEffect, useState } from 'react';
import { Drawer } from '@aceandtate/ds';
import { useIntl } from 'react-intl';
import authMessages from 'messages/authentication';
import { useUserState } from 'services/userService';
import { useRouter } from 'next/router';
import * as Styles from './styles';
import Image from 'next/image';
import AuthenticationFormV2, { AuthenticationFormV2State } from 'components/AuthenticationFormV2';
import { trackArtificialPageView } from 'tracking/helpers';
import camelCase from 'lodash/camelCase';
import ProfileMenu from 'views/ProfileView/partials/ProfileMenu/ProfileMenu';

export default function LoginDrawer(): ReactElement {
  const router = useRouter();
  const [authFormState, setAuthFormState] = useState<AuthenticationFormV2State>('email');
  const { isUserLoggedIn, hasValidAccessToken } = useUserState();
  const [open, setOpen] = useState(!hasValidAccessToken && !!router.query.auth);
  const intl = useIntl();

  useEffect(() => {
    if (router.query.auth === 'success' || router.query.auth === 'failed') {
      delete router.query.auth;

      // removing the path query used by dynamic redirects
      // will be redundant when using asPath as reference below
      delete router.query.path;

      router.replace(
        {
          pathname: router.asPath.split('?')[0],
          query: router.query
        },
        undefined,
        { shallow: true }
      );
    } else {
      if (router.query.auth && !hasValidAccessToken && !isUserLoggedIn) {
        !open && trackArtificialPageView('authDrawerOpened');
        setOpen(true);
      }
    }
  }, [isUserLoggedIn, hasValidAccessToken, router.query.auth]);

  function onClose() {
    setOpen(false);
    trackArtificialPageView('authDrawerClosed');
  }

  function onFormStateChange(tabIndex: AuthenticationFormV2State) {
    trackArtificialPageView(`authDrawer${camelCase(tabIndex).replace(/./, c => c.toUpperCase())}Tab`);
    setAuthFormState(tabIndex);
  }

  return (
    <Drawer
      open={open}
      position='right'
      onClose={onClose}
      headerProps={{
        children: (
          <Image
            src='/static/login/login-header.jpg'
            fill
            sizes='500px, (max-width: 500px) 100vw'
            style={{
              objectFit: 'cover'
            }}
            alt='Ace & Tate | Login image'
          />
        ),
        color: 'secondary',
        title:
          authFormState === 'register'
            ? intl.formatMessage(authMessages.registerTitle)
            : intl.formatMessage(authMessages.login),
        variant: authFormState === 'email' ? 'large' : 'small'
      }}
    >
      <Styles.LoginDrawerContent data-cs-signal-enabled>
        <AuthenticationFormV2 onFormChange={onFormStateChange} authenticationType={authFormState} />
        {authFormState === 'success' && <ProfileMenu onClickLink={onClose} />}
      </Styles.LoginDrawerContent>
    </Drawer>
  );
}
