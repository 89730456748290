import { defineMessages } from 'react-intl';

export default defineMessages({
  emailLabel: {
    defaultMessage: 'Email address',
    id: 'productStock.emailLabel'
  },
  oosModalBody: {
    defaultMessage:
      'We’ll let you know as soon as these are are back in stock. We’ll only use your e-mail to send you stock updates for this item.',
    id: 'productStock.oosModalBody'
  },
  oosModalCta: {
    defaultMessage: 'Notify me',
    id: 'productStock.oosModalCta'
  },
  oosModalTitle: {
    defaultMessage: 'Oh nooo',
    id: 'productStock.oosModalTitle'
  }
});
