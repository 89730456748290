import styled from 'styled-components';
import { breakpointRules, grid, brandColors, borderRadius } from '@aceandtate/ds';

export const Option = styled.li`
  padding: ${grid[12]} ${grid[12]} !important;
  cursor: pointer;
  display: block;
  width: 100%;
  && {
    align-items: flex-start;
  }
  border-radius: ${borderRadius.small};

  :focus-visible {
    outline: none;
    background: ${brandColors.dark10};
  }

  :hover {
    background: ${brandColors.dark10};
  }
`;

export const MessageWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: ${grid[8]};
  @media ${breakpointRules.tablet} {
    align-items: flex-start;
    flex-flow: column;
  }
`;
