import React from 'react';
import { Button, InputControl, TextInput, Typography, grid } from '@aceandtate/ds';
import { FormattedMessage, useIntl } from 'react-intl';
import authenticationMessages from 'messages/authentication';
import newsletterFormMessages from 'messages/newsletterForm';
import * as Styles from '../styles';
import { ShopstoryAlign } from '..';
import PrivacyPolicyCheckbox, { StyledPrivacyPolicyLink } from 'components/PrivacyPolicyCheckbox';
import { paths } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import useValidationOptions from 'utils/hooks/useValidationOptions';
import { ErrorMessage } from '@hookform/error-message';

export type Props = {
  onSubmit: (data: FormData) => void;
  color: 'dark' | 'light';
  align: ShopstoryAlign;
};

export type FormData = { email: string; privacyPolicy: boolean; dateOfBirth: string };

export default function FormFields(props: Props) {
  const { onSubmit, color, align } = props;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const intl = useIntl();
  const validationOptions = useValidationOptions();

  return (
    <Styles.StyledForm onSubmit={handleSubmit(onSubmit)} align={align} noValidate>
      <InputControl id='email-newsletter-plugin' style={{ width: '100%' }}>
        <InputControl.Label isHidden>
          <FormattedMessage {...newsletterFormMessages.emailAddress} />
        </InputControl.Label>
        <TextInput
          fullWidth
          name='email'
          type='email'
          data-testid='email-input-newsletter-plugin'
          required
          {...register(`email`, { ...validationOptions.emailValidation })}
          placeholder={intl.formatMessage(newsletterFormMessages.yourEmail)}
        />
        <ErrorMessage
          name='email'
          errors={errors}
          render={({ message }) => <InputControl.Validation status='error'>{message}</InputControl.Validation>}
        />
      </InputControl>

      <Styles.PrivacyPolicyContainer color={color}>
        <InputControl id='privacyPolicy'>
          <Controller
            render={({ field }) => (
              <PrivacyPolicyCheckbox
                checked={field.value}
                onChange={field.onChange}
                id='newsletter-modal'
                label={
                  <Typography variant='bodyS'>
                    <FormattedMessage
                      {...authenticationMessages.privacyPolicyStatement}
                      values={{
                        privacyPolicy: (
                          <StyledPrivacyPolicyLink href={paths.privacyPolicy} target='blank' rel='noreferrer noopener'>
                            <FormattedMessage {...authenticationMessages.privacyPolicy} />
                          </StyledPrivacyPolicyLink>
                        )
                      }}
                    />
                  </Typography>
                }
              >
                <PrivacyPolicyCheckbox.DateOfBirth {...register('dateOfBirth')} />
              </PrivacyPolicyCheckbox>
            )}
            name='privacyPolicy'
            rules={validationOptions.requiredField}
            control={control}
          />
          <ErrorMessage
            name='privacyPolicy'
            errors={errors}
            render={({ message }) => <InputControl.Validation status='error'>{message}</InputControl.Validation>}
          />
        </InputControl>
      </Styles.PrivacyPolicyContainer>
      <Button
        type='submit'
        style={{ marginTop: grid[16], width: 'fit-content' }}
        color={color}
        data-testid='signup-button-newsletter-plugin'
      >
        <FormattedMessage {...newsletterFormMessages.signUp} />
      </Button>
    </Styles.StyledForm>
  );
}
