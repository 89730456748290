import styled from 'styled-components';
import { breakpointRules, grid } from '@aceandtate/ds';

export const FooterLinksWrapper = styled.ul`
  margin: 0;
  list-style: none;
  padding-left: 0;
  column-count: 1;
  margin-left: -16px;
  column-gap: ${grid[24]};

  @media ${breakpointRules.desktop} {
    column-count: 2;
    column-gap: 60px;
    column-width: 220px;
  }

  @media ${breakpointRules.tablet} {
    column-count: 2;
  }
`;

export const FooterLinkWrapper = styled.li`
  margin-bottom: ${grid[12]};
  margin-left: ${grid[40]};
  cursor: pointer;
  button {
    padding: 0px;
  }

  button:hover,
  :hover {
    text-decoration: underline;
  }

  h4 {
    margin: 0;
  }
`;

export const FooterLinkPopover = styled.div`
  padding: 22px 16px;
  overflow: auto;
  max-height: 500px;

  a {
    display: block;
    text-transform: capitalize;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
