import { trackGaEvent, trackPageEvent } from '../helpers';

type NewsletterRequestedOptions = {
  formType: string;
  country: string;
  localeLang: string;
  hashM256: string;
  hashM: string;
};

export function newsletterRequested(options: NewsletterRequestedOptions) {
  const { formType, country, localeLang, hashM256, hashM } = options;

  trackPageEvent('newsletterRequested');
  return trackGaEvent({
    country,
    event: 'newsletterRequested',
    formType,
    hashM256,
    hashM,
    localeLang
  });
}
