import { captureException } from '@sentry/nextjs';
import { SWRCacheKeys } from 'config/cache';
import { WishlistContext } from 'features/Wishlist';
import { useContext } from 'react';
import useSWR, { useSWRConfig } from 'swr';

import { Customer, Prescription, ShippingAddress, User } from 'types/solidus';
import { setUserCookies, clearUserCookies, evaluateAccessToken } from 'utils/helpers/userCookies';
import { useFetch } from 'utils/helpers/customFetch';
import { useSubscribe } from './newsletterService';
import { useFetchCart } from './cartService';
import { useNinetailed } from '@ninetailed/experience.js-next';

export function useCheckIfEmailInUse() {
  const fetch = useFetch();
  return async (email: string) => {
    const res = await fetch('/email_used', {
      body: { email: email },
      method: 'POST'
    });

    return {
      ...res,
      data: {
        emailExists: res.status >= 200 && res.status < 300
      }
    };
  };
}

export function useCheckIfEmailIsValid() {
  const fetch = useFetch();

  return async (email: string) => {
    const res = await fetch('/email_valid', {
      body: { email: email },
      method: 'POST'
    });

    return {
      ...res,
      data: {
        emailExists: res.status >= 200 && res.status < 300
      },
      success: res.data?.email && res.data.email[0] === 'has already been taken' ? true : res.success
    };
  };
}

export function useFetchInvoice() {
  const fetch = useFetch();
  return async (orderNumber: string) => {
    const res = await fetch(`/me/invoice/${orderNumber}`);
    return res;
  };
}

export function useCancelOrder() {
  const fetch = useFetch();
  return async (orderNumber: string) => {
    const res = await fetch(`/me/orders/${orderNumber}/cancel`);
    return res;
  };
}

const USER_PATH = SWRCacheKeys.user;
const ADDRESS_PATH = SWRCacheKeys.userAddress;

function useRecoverPassord() {
  const fetch = useFetch();

  return async (email: string) => {
    const response = await fetch('/user/recover_password', {
      body: { user: { email } },
      method: 'POST'
    });
    return response;
  };
}

function useResetPassord() {
  const fetch = useFetch();

  return async (reset_password_token: string, password: string) => {
    const response = await fetch('/user/reset_password', {
      body: { user: { password, reset_password_token } },
      method: 'PUT'
    });
    return response;
  };
}

function useUpdateUserCustomer() {
  const fetch = useFetch<User>();
  const { mutate } = useSWRConfig();

  return async (customer: Partial<Customer>) => {
    const response = await fetch(USER_PATH, {
      body: { customer },
      method: 'PUT'
    });

    if (response.success) {
      mutate(USER_PATH, user => ({
        ...user,
        customer: response.data
      }));
    }

    return response;
  };
}

type NewUser = {
  email: string;
  password: string;
  dateOfBirth?: string;
  newsletterSubscribe?: boolean;
  customer?: Partial<Customer>;
};

function useRegisterUser() {
  const { mutate } = useSWRConfig();
  const fetch = useFetch<User>();
  const subscribe = useSubscribe();
  const updateUserCustomer = useUpdateUserCustomer();
  const fetchUserProfile = useFetchUserProfile();

  return async (user: NewUser) => {
    const response = await fetch('/users', {
      body: { user },
      method: 'POST'
    });

    if (user.newsletterSubscribe) {
      subscribe({ email: user.email, formType: 'register', date_of_birth: user.dateOfBirth });
    }

    if (response.success) {
      setUserCookies(response.data, response.data.auth_token);
      let userCustomer = {};
      if (user.customer) {
        const updatedCustomerResponse = await updateUserCustomer(user.customer);

        if (updatedCustomerResponse.success) {
          userCustomer = updatedCustomerResponse.data;
        }
      }

      const fullUser = await fetchUserProfile();
      if (fullUser.success && fullUser.data) {
        mutate(USER_PATH, fullUser.data, false);
      } else {
        mutate(USER_PATH, { ...response.data, customer: userCustomer }, false);
      }
    }

    return response;
  };
}

type LoginData = {
  email: string;
  password: string;
};

type LoginResponse = {
  user: User;
  jwt: string;
};

function useUserLogin() {
  const { mutate } = useSWRConfig();
  const fetch = useFetch<LoginResponse>();
  const { migrateWishlist } = useContext(WishlistContext);
  const fetchUserProfile = useFetchUserProfile();
  const fetchUserCart = useFetchCart();
  const { identify } = useNinetailed();

  return async (loginData: LoginData) => {
    const res = await fetch('/login', {
      body: { auth: loginData },
      method: 'POST'
    });

    if (res.success && res.data.jwt) {
      setUserCookies(res.data.user, res.data.jwt);
      try {
        identify('', { newsletter: res.data.user.customer.newsletter });
        migrateWishlist();
        fetchUserCart();
      } catch (e) {
        captureException(e);
      }

      const response = await fetchUserProfile();
      if (response.success && response.data) {
        mutate(USER_PATH, response.data, false);
      } else {
        mutate(USER_PATH, res.data.user, false);
      }
    }

    return res;
  };
}

type EasyLoginData = {
  email: string;
  token: string;
};

function useEasyLoginUser() {
  const { mutate } = useSWRConfig();
  const fetch = useFetch<LoginResponse>();
  const { migrateWishlist } = useContext(WishlistContext);
  const fetchUserProfile = useFetchUserProfile();
  const { identify } = useNinetailed();

  return async (loginData: EasyLoginData) => {
    const res = await fetch('/easy_login', {
      body: { auth: loginData },
      method: 'POST'
    });

    if (res.success && res.data.jwt) {
      setUserCookies(res.data.user, res.data.jwt);
      try {
        identify('', { newsletter: res.data.user.customer.newsletter });
        migrateWishlist();
      } catch (e) {
        captureException(e);
      }

      const response = await fetchUserProfile();
      if (response.success && response.data) {
        mutate(USER_PATH, response.data, false);
      } else {
        mutate(USER_PATH, res.data.user, false);
      }
    }

    return res;
  };
}

export function useGetHashedEmail() {
  const fetch = useFetch();

  async function getHashedEmail(email: string) {
    const res = await fetch(`/users/email_hash`, { method: 'GET', params: { email } });

    return res;
  }

  return getHashedEmail;
}

type RequestEasyLoginData = {
  email: string;
  /** Requires an unlocalized path ('/profile') or full URL, preferably using: encodeURI(window.location.href) */
  redirectUrl?: string;
};

function useRequestEasyLogin() {
  const fetch = useFetch();

  return async (easyLoginParams: RequestEasyLoginData) => {
    const res = await fetch('/generate_easy_login', {
      method: 'POST',
      params: { email: easyLoginParams.email, redirect_url: encodeURIComponent(easyLoginParams.redirectUrl) }
    });

    return res;
  };
}

function useLogoutUser() {
  const { mutate } = useSWRConfig();
  return (redirectUrl?: string) => {
    clearUserCookies();
    mutate(USER_PATH, null, false);
    redirectUrl && window.location.assign(redirectUrl);
  };
}

function useFetchUserProfile() {
  const fetch = useFetch<User>();
  return async () => fetch(USER_PATH);
}

export function useUserService() {
  const loginUser = useUserLogin();
  const logoutUser = useLogoutUser();
  const registerNewUser = useRegisterUser();
  const requestEasyLogin = useRequestEasyLogin();
  const easyLoginUser = useEasyLoginUser();
  const updateUserCustomer = useUpdateUserCustomer();
  const updateUserShippingAddress = useUpdateUserShippingAddresses();
  const recoverPassword = useRecoverPassord();
  const resetPassword = useResetPassord();
  const fetchInvoice = useFetchInvoice();
  const addPrescription = useAddUserPrescription();
  const deletePrescription = useDeleteUserPrescription();
  const updatePrescription = useUpdateUserPrescription();

  return {
    addPrescription,
    deletePrescription,
    easyLoginUser,
    fetchInvoice,
    loginUser,
    logoutUser,
    recoverPassword,
    registerNewUser,
    requestEasyLogin,
    resetPassword,
    updatePrescription,
    updateUserCustomer,
    updateUserShippingAddress
  };
}

export function useUserState(options = {}) {
  const fetchUserProfile = useFetchUserProfile();
  const hasValidAccessToken = !!evaluateAccessToken();

  const { data, isValidating, error } = useSWR(
    USER_PATH,
    async () => {
      if (hasValidAccessToken) {
        const response = await fetchUserProfile();

        if (response.success === false) {
          clearUserCookies();
          throw new Error('Failed to fetch user profile');
        }

        return response.data;
      } else return null;
    },
    options
  );

  const isUserLoggedIn = hasValidAccessToken && !!data?.id;
  const userProfile = isUserLoggedIn ? (data as User) : null;
  const isUserLoading = !error && data === undefined;

  // isUserLoading  will only be true on initial load of data while
  // isValidating will also be true whenever data is being-refetched e.g. on re-focus of a tab
  // for conditional rendering, most of the time isUserLoading  should be used
  return { hasValidAccessToken, isUserLoading, isUserLoggedIn, isValidating, userProfile };
}

type UserAddressesResponse = {
  shipping_address: ShippingAddress;
  billing_address: ShippingAddress;
};

export function useUserAddresses(options = {}) {
  const getUserAddresses = useGetUserAddresses();
  return useSWR(
    ADDRESS_PATH,
    async () => {
      const response = await getUserAddresses();
      if (!response.success) {
        throw new Error('Failed to fetch user addresses');
      }

      return response.data;
    },
    options
  );
}

export function useUserPrescriptions(options = {}) {
  const getUserPrescriptions = useGetUserPrescriptions();
  return useSWR(
    ADDRESS_PATH,
    async () => {
      const response = await getUserPrescriptions();
      if (!response.success) {
        throw new Error('Failed to fetch user prescriptions');
      }

      return response.data;
    },
    options
  );
}

export function useGetUserAddresses() {
  const fetch = useFetch<UserAddressesResponse>();
  return async () => fetch('/me/addresses');
}

type ChangeUserPasswordBody = {
  old_password: string;
  password: string;
};

export function useChangeUserPassword() {
  const fetch = useFetch();
  return async (body: ChangeUserPasswordBody) =>
    fetch('/me/change_password', {
      body,
      method: 'PUT'
    });
}

export function useUpdateUserShippingAddresses() {
  const fetch = useFetch();
  return async (address: Partial<ShippingAddress>) =>
    fetch('/me/addresses', {
      body: { address },
      method: 'PUT'
    });
}

export function useGetUserPrescriptions() {
  const fetch = useFetch<User>();
  return async () => fetch('/me/prescriptions');
}

export function useAddUserPrescription() {
  const fetch = useFetch();
  return async (prescription: Partial<Prescription>) =>
    fetch('/me/prescriptions', {
      body: { prescription },
      method: 'POST'
    });
}

export function useUpdateUserPrescription() {
  const fetch = useFetch();
  return async (prescription: Partial<Prescription>) =>
    fetch(`/me/prescriptions/${prescription.id}`, {
      body: { prescription },
      method: 'PUT'
    });
}

export function useDeleteUserPrescription() {
  const { mutate } = useSWRConfig();
  const fetch = useFetch();
  return async function (id: number) {
    const response = await fetch(`/me/prescriptions/${id}`, {
      method: 'DELETE'
    });

    if (response.success) {
      mutate(
        USER_PATH,
        user => ({
          ...user,
          prescriptions: user.prescriptions.filter(x => x.id !== id)
        }),
        false
      );
    }

    return response;
  };
}

type AnonymousEventProps = {
  category: string;
  email?: string;
  emailHash?: string;
  eventType: string;
};

// note: user does not need to be logged in
export function useAnonymousUserEvents() {
  const fetch = useFetch();
  return async function ({ email, emailHash, category, eventType }: AnonymousEventProps) {
    return await fetch(`/anonymous_user_events`, {
      body: { category, email, email_hash: emailHash, event_type: eventType },
      method: 'POST'
    });
  };
}
