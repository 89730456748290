import React, { AnchorHTMLAttributes, MouseEventHandler } from 'react';
import Link from 'next/link';

type ConditionalLinkProps = {
  children: React.ReactNode;
  externalUrl?: string;
  href?: string;
  isLink?: boolean;
  onClickCapture?: MouseEventHandler;
  /** Prefetch the page in the background. Any <Link /> that is in the viewport (initially or through scroll) will be preloaded.
   * Prefetch can be disabled by passing prefetch={false}. When prefetch is set to false, prefetching will still occur on hover in pages router but not in app router. Pages using Static Generation will preload JSON files with the data for faster page transitions. Prefetching is only enabled in production.
   * @defaultValue — true */
  prefetch?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export default function ConditionalLink(props: ConditionalLinkProps) {
  const { children, href, isLink = true, prefetch, externalUrl, ...anchorProps } = props;
  if (externalUrl) {
    return (
      <a href={externalUrl} rel='noopener noreferrer' target='_blank' {...anchorProps}>
        {children}
      </a>
    );
  } else if (href && isLink) {
    return (
      <Link href={href} prefetch={prefetch || undefined} {...anchorProps}>
        {children}
      </Link>
    );
  } else {
    return <>{children}</>;
  }
}
