/* Constants */
const countries = require('./countries.json');
const variableGiftcardPrices = require('../giftcard-prices');

const getCountryIso = name => countries.find(country => country.iso_name === name);
const giftcardsByCurrency = currency =>
  variableGiftcardPrices[currency]
    ? { ...variableGiftcardPrices[currency], currency }
    : { ...variableGiftcardPrices.EUR, currency: 'EUR' };

const CURRENCIES = {
  CHF: 'CHF',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  SEK: 'SEK',
  USD: 'USD'
};

// CURRENTLY NOT USED
// temp hardcoded conversion rates used to push sales to emarsys
// based on Tableau_data_mapping https://docs.google.com/spreadsheets/d/1Yh4vva4iP8CyLjw2TSm_H7S7CoM4HDGwD2XBnIt3QHE/edit#gid=492236347
// Last updated 25 May 2018
// const CONVERSION_RATES = {
//   CHF: 1.13,
//   EUR: 1,
//   DKK: 0.134,
//   GBP: 1.143,
//   SEK: 0.098
// };

// IMPORTANT:
// Before adding a language here, add it to
// Contentful first
const LANGUAGES = {
  DE: 'de',
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  NL: 'nl'
};

const defaultConfig = {
  basePrice: 125,
  clipOnPrice: 60,
  currency: 'EUR',
  giftcards: giftcardsByCurrency('EUR'),
  hasAgeRequirement: false,
  hasChatWidget: false,
  hasContactLensesDisclaimer: false,
  hasEnterPrescription: true,
  hasFreeEyeTest: false,
  hasFreeReturns: false,
  hasGiftCards: true,
  hasHTO: false,
  hasHTORecommendations: false,
  hasInsurance: false,
  hasPaidEyeTest: false,
  hasPostNL: false,
  hasRetailStores: false,
  hasSmartReturns: false,
  hasStorePickup: false,
  htoLimit: 4,
  limitedEditionPrice: 170,
  openingHours: {
    monToFri: { from: '9:00', to: '17:00' }
  },
  premiumPrice: 190,
  productPriceArchive: 49,
  productPrice24: 25,
  productPrice248: 310,
  productPrice278: 350,
  productPrice48: 70,
  productPrice78: 110,
  productPrice8: 10,
  requiresLanguagePrompt: false,
  sameDayServicePrice: 30,
  showStreetNumberBeforeStreetName: false,
  storeID: 9
};

const defaultContact = {
  email: 'hello@aceandtate.com',
  officePhone: '+31 (0)20 261 36 47',
  phone: '+44 (0)20 351 422 72',
  whatsapp: '+31 97010240634'
};

// TODO: Add test to validate the the shape of all shippingCountries
// is valid
const shippingCountries = [
  {
    config: {
      ...defaultConfig,
      hasChatWidget: true,
      hasFreeEyeTest: true,
      hasFreeReturns: true,
      hasRetailStores: true,
      hasSmartReturns: true,
      hasStorePickup: true,
      phoneCountryCode: '+43 (0)',
      storeID: 27
    },
    contact: {
      ...defaultContact,
      email: 'hallo@aceandtate.de',
      phone: '+49 (0)30-56 79 66 33' // GERMANY
    },
    country: getCountryIso('AUSTRIA'),
    languages: [LANGUAGES.DE, LANGUAGES.EN],
    name: 'AUSTRIA'
  },
  {
    config: {
      ...defaultConfig,
      hasChatWidget: true,
      hasFreeEyeTest: true,
      hasFreeReturns: true,
      hasInsurance: false,
      hasRetailStores: true,
      hasSmartReturns: true,
      hasStorePickup: true,
      phoneCountryCode: '+32 (0)',
      requiresLanguagePrompt: true,
      storeID: 5
    },
    contact: {
      ...defaultContact,
      email: 'hallo@aceandtate.be',
      phone: '+32 (0)3 303 70 37'
    },
    country: getCountryIso('BELGIUM'),
    languages: [LANGUAGES.NL, LANGUAGES.FR, LANGUAGES.EN],
    name: 'BELGIUM'
  },
  {
    config: {
      ...defaultConfig,
      storeID: 10
    },
    contact: defaultContact,
    country: getCountryIso('CZECH REPUBLIC'),
    languages: [LANGUAGES.EN],
    name: 'CZECH REPUBLIC'
  },
  {
    config: {
      ...defaultConfig,
      basePrice: 1250,
      clipOnPrice: 600,
      currency: CURRENCIES.DKK,
      giftcards: giftcardsByCurrency(CURRENCIES.DKK),
      hasChatWidget: true,
      hasFreeEyeTest: true,
      hasFreeReturns: true,
      hasRetailStores: true,
      hasSmartReturns: true,
      hasStorePickup: true,
      limitedEditionPrice: 1700,
      phoneCountryCode: '+45 (0)',
      premiumPrice: 1900,
      productPriceArchive: 490,
      productPrice24: 250,
      productPrice248: 3050,
      productPrice278: 3500,
      productPrice48: 650,
      productPrice78: 1100,
      productPrice8: 100,
      sameDayServicePrice: 250,
      storeID: 4
    },
    contact: {
      ...defaultContact,
      email: 'hello@aceandtate.dk',
      phone: '+45 (0) 89 87 16 66'
    },
    country: getCountryIso('DENMARK'),
    languages: [LANGUAGES.EN],
    name: 'DENMARK'
  },
  {
    config: {
      ...defaultConfig,
      storeID: 12
    },
    contact: defaultContact,
    country: getCountryIso('FINLAND'),
    languages: [LANGUAGES.EN],
    name: 'FINLAND'
  },
  {
    config: {
      ...defaultConfig,
      hasFreeReturns: true,
      hasSmartReturns: true,
      showStreetNumberBeforeStreetName: true,
      storeID: 13
    },
    contact: {
      ...defaultContact,
      email: 'hello@aceandtate.fr',
      phone: '+32 (0)3 303 70 37'
    },
    country: getCountryIso('FRANCE'),
    languages: [LANGUAGES.FR, LANGUAGES.EN],
    name: 'FRANCE'
  },
  {
    config: {
      ...defaultConfig,
      hasChatWidget: true,
      hasFreeEyeTest: true,
      hasFreeReturns: true,
      hasRetailStores: true,
      hasSmartReturns: true,
      hasStorePickup: true,
      phoneCountryCode: '+49 (0)',
      storeID: 6
    },
    contact: {
      ...defaultContact,
      email: 'hallo@aceandtate.de',
      phone: '+49 (0)30-56 79 66 33'
    },
    country: getCountryIso('GERMANY'),
    languages: [LANGUAGES.DE, LANGUAGES.EN],
    name: 'GERMANY'
  },
  {
    config: {
      ...defaultConfig,
      hasAgeRequirement: true,
      hasContactLensesDisclaimer: true,
      hasFreeEyeTest: true,
      hasMultifocalDisabled: true,
      hasRequiresVerifiedPrescription: true,
      hasRetailStores: true,
      hasStorePickup: false,
      phoneCountryCode: '+353 (0)',
      showStreetNumberBeforeStreetName: true,
      storeID: 16
    },
    contact: {
      ...defaultContact
    },
    country: getCountryIso('IRELAND'),
    languages: [LANGUAGES.EN],
    name: 'IRELAND'
  },
  {
    config: {
      ...defaultConfig,
      storeID: 17
    },
    contact: defaultContact,
    country: getCountryIso('ITALY'),
    languages: [LANGUAGES.EN],
    name: 'ITALY'
  },
  {
    config: {
      ...defaultConfig,
      showStreetNumberBeforeStreetName: true,
      storeID: 20
    },
    contact: defaultContact,
    country: getCountryIso('LUXEMBOURG'),
    languages: [LANGUAGES.EN],
    name: 'LUXEMBOURG'
  },
  {
    config: {
      ...defaultConfig,
      hasChatWidget: true,
      hasFreeEyeTest: true,
      hasFreeReturns: true,
      hasInsurance: true,
      hasPostNL: true,
      hasRetailStores: true,
      hasSmartReturns: true,
      hasStorePickup: true,
      phoneCountryCode: '+31 (0)',
      storeID: 3
    },
    contact: {
      ...defaultContact,
      email: 'hallo@aceandtate.nl',
      phone: '+31 (0)20-2050738'
    },
    country: getCountryIso('NETHERLANDS'),
    languages: [LANGUAGES.NL, LANGUAGES.EN],
    name: 'NETHERLANDS'
  },
  {
    config: {
      ...defaultConfig,
      storeID: 21
    },
    contact: defaultContact,
    country: getCountryIso('POLAND'),
    languages: [LANGUAGES.EN],
    name: 'POLAND'
  },
  {
    config: {
      ...defaultConfig,
      storeID: 22
    },
    contact: defaultContact,
    country: getCountryIso('PORTUGAL'),
    languages: [LANGUAGES.EN],
    name: 'PORTUGAL'
  },
  {
    config: {
      ...defaultConfig,
      storeID: 23
    },
    contact: defaultContact,
    country: getCountryIso('ROMANIA'),
    languages: [LANGUAGES.EN],
    name: 'ROMANIA'
  },
  {
    config: {
      ...defaultConfig,
      hasFreeEyeTest: true,
      hasRetailStores: true,
      hasStorePickup: true,
      storeID: 26
    },
    contact: defaultContact,
    country: getCountryIso('SPAIN'),
    languages: [LANGUAGES.ES, LANGUAGES.EN],
    name: 'SPAIN'
  },
  {
    config: {
      ...defaultConfig,
      basePrice: 1750,
      clipOnPrice: 850,
      currency: CURRENCIES.SEK,
      giftcards: giftcardsByCurrency(CURRENCIES.SEK),
      hasChatWidget: true,
      hasFreeEyeTest: true,
      hasFreeReturns: true,
      hasRetailStores: true,
      hasSmartReturns: true,
      hasStorePickup: true,
      limitedEditionPrice: 2400,
      phoneCountryCode: '+46 (0)',
      premiumPrice: 2650,
      productPriceArchive: 700,
      productPrice24: 350,
      productPrice248: 4250,
      productPrice278: 4900,
      productPrice48: 900,
      productPrice78: 1550,
      productPrice8: 140,
      sameDayServicePrice: 350,
      storeID: 8
    },
    contact: {
      ...defaultContact,
      email: 'hello@aceandtate.se',
      phone: '+46 (0) 84 08 38 44 4'
    },
    country: getCountryIso('SWEDEN'),
    languages: [LANGUAGES.EN],
    name: 'SWEDEN'
  },
  {
    config: {
      ...defaultConfig,
      basePrice: 170,
      clipOnPrice: 90,
      currency: CURRENCIES.CHF,
      giftcards: giftcardsByCurrency(CURRENCIES.CHF),
      hasFreeEyeTest: true,
      hasGiftCards: true,
      hasRetailStores: true,
      hasStorePickup: true,
      limitedEditionPrice: 230,
      phoneCountryCode: '+41',
      premiumPrice: 260,
      productPriceArchive: 67,
      productPrice24: 37,
      productPrice248: 450,
      productPrice278: 525,
      productPrice48: 90,
      productPrice78: 165,
      productPrice8: 15,
      requiresLanguagePrompt: true,
      sameDayServicePrice: 40,
      storeID: 30
    },
    contact: defaultContact,
    country: getCountryIso('SWITZERLAND'),
    languages: [LANGUAGES.DE, LANGUAGES.EN, LANGUAGES.FR],
    name: 'SWITZERLAND'
  },
  {
    config: {
      ...defaultConfig,
      basePrice: 125,
      clipOnPrice: 60,
      currency: CURRENCIES.GBP,
      giftcards: giftcardsByCurrency(CURRENCIES.GBP),
      hasAgeRequirement: true,
      hasChatWidget: true,
      hasContactLensesDisclaimer: true,
      hasFreeEyeTest: true,
      hasFreeReturns: true,
      hasMultifocalDisabled: true,
      hasPaidEyeTest: true,
      hasRequiresVerifiedPrescription: true,
      hasRetailStores: true,
      hasSmartReturns: true,
      hasStorePickup: false,
      limitedEditionPrice: 170,
      phoneCountryCode: '+44 (0)',
      premiumPrice: 190,
      productPriceArchive: 49,
      productPrice24: 25,
      productPrice248: 310,
      productPrice278: 350,
      productPrice48: 70,
      productPrice78: 110,
      productPrice8: 10,
      sameDayServicePrice: 30,
      showStreetNumberBeforeStreetName: true,
      storeID: 7
    },
    contact: {
      ...defaultContact,
      email: 'hello@aceandtate.co.uk'
    },
    country: getCountryIso('UNITED KINGDOM'),
    languages: [LANGUAGES.EN],
    name: 'UNITED KINGDOM'
  },
  {
    config: {
      ...defaultConfig,
      basePrice: 125,
      clipOnPrice: 75,
      currency: CURRENCIES.USD,
      giftcards: giftcardsByCurrency(CURRENCIES.USD),
      hasEnterPrescription: false,
      hasFreeReturns: false,
      hasPhoneCX: false,
      limitedEditionPrice: 185,
      premiumPrice: 225,
      productPriceArchive: 49,
      productPrice24: 35,
      productPrice248: 275,
      productPrice278: 325,
      productPrice48: 75,
      productPrice78: 125,
      productPrice8: 12,
      sameDayServicePrice: 30,
      openingHours: {
        monToFri: { from: '9:00', to: '17:00 (CET)' }
      },
      storeID: 31
    },
    contact: {
      ...defaultContact,
      email: 'hello@aceandtate.com'
    },
    country: getCountryIso('UNITED STATES'),
    languages: [LANGUAGES.EN],
    name: 'UNITED STATES'
  }
];

module.exports = {
  shippingCountries
};
