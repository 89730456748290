import { defineMessages } from 'react-intl';

const messages = defineMessages({
  company: {
    defaultMessage: 'Company',
    id: 'addressForm.company'
  },
  postalCode: {
    defaultMessage: 'Postal code',
    id: 'addressForm.postalCode'
  },
  select: {
    defaultMessage: 'Select',
    id: 'formLabels.select'
  },
  yourEmail: {
    defaultMessage: 'Your Email',
    id: 'formLabels.yourEmail'
  },
  dateOfBirth: {
    defaultMessage: 'Date of birth',
    id: 'formLabels.dateOfBirth'
  },
  optional: {
    defaultMessage: 'optional',
    id: 'formLabels.optional'
  }
});

export default messages;
