import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { borderRadius, brandColors, zIndex } from '@aceandtate/ds';
import styled from 'styled-components';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = styled(PopoverPrimitive.Trigger)``;

const StyledPopoverContent = styled(PopoverPrimitive.Content)`
  background-color: ${brandColors.light};
  border-radius: ${borderRadius.small};
  z-index: ${zIndex.selectMenu};
  overflow: hidden;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

type PopoverProps = {
  children: React.ReactNode;
  props: PopoverPrimitive.PopoverContentProps;
};

export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverPrimitive.PopoverContentProps>(
  ({ children, ...props }: PopoverProps, forwardedRef) => (
    <PopoverPrimitive.PopoverPortal>
      <StyledPopoverContent sideOffset={5} {...props} ref={forwardedRef}>
        {children}
      </StyledPopoverContent>
    </PopoverPrimitive.PopoverPortal>
  )
);
