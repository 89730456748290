import React, { useState, useContext, useEffect } from 'react';
import { Button, Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';
import IncompatibleCountryModal from 'components/IncompatibleCountryModal';
import ContactLensConfigurator, { LensTypeID } from 'components/Configurators/ContactLensConfigurator';
import FrameConfigurator from 'components/Configurators/FrameConfigurator';
import Modal from 'components/Modal';
import OnlineProductStock from 'components/OnlineProductStock';
import { ServicesContext } from 'services/context';
import { useFetchContactLensPromotions } from 'services/promotionsService';
import { useProduct } from 'services/productsService';
import useAddToCart from 'utils/hooks/useAddToCart';
import messages from './messages';
import { ConfiguratorProvider } from 'components/Configurators/FrameConfigurator/configuratorStore';

type Props = {
  children: React.ReactNode;
  buttonColor?: React.ComponentProps<typeof Button>['color'];
  buttonVariant?: React.ComponentProps<typeof Button>['variant'];
  sku?: string;
};

const TRIAL_DISABLED_COUNTRIES = ['gb', 'ie', 'dk', 'es'];

function findLensType(sku: string): LensTypeID {
  if (sku === 'daily-contacts-trial-regular' || sku === 'daily-contacts-regular') {
    return 'regular';
  } else if (sku === 'daily-contacts-trial-toric' || sku === 'daily-contacts-toric') {
    return 'toric';
  }

  return null;
}

export default function ProductConfiguratorPlugin(props: Props) {
  const { sku, buttonColor, buttonVariant, children } = props;
  const { locale, webStore } = useContext(ServicesContext);
  const [isInvalidCountry, setIsInvalidCountry] = useState(false);
  const [isProductConfiguratorOpen, setIsProductConfiguratorOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isContactLens = sku.includes('daily-contacts');
  const [lensType, setLensType] = useState(isContactLens ? findLensType(sku) : null);
  const isContactLensTrial = isContactLens && sku.includes('trial');
  const frameProductQuery = useProduct(isContactLens ? null : sku);

  // contact lens variants are hardcoded as base product to fetch all child variants
  const regularContactLensQuery = useProduct(
    isContactLens ? `daily-contacts-minus100${isContactLensTrial ? '-trial6' : '-cp30'}` : null
  );
  const toricContactLensQuery = useProduct(
    isContactLens ? `daily-contacts-toric-minus100-minus125-15${isContactLensTrial ? '-trial6' : '-cp30'}` : null
  );

  const promotionQuery = useFetchContactLensPromotions();
  const addToCart = useAddToCart();

  const product = isContactLens ? regularContactLensQuery.data : frameProductQuery.data;
  const toricLensProduct = isContactLens ? toricContactLensQuery.data : null;
  const hasError = isContactLens ? regularContactLensQuery.error : frameProductQuery.error;
  const productShouldBeConfigured = product?.productType === 'frame' || isContactLens;
  const isDisabledTrial =
    product?.productType === 'contact_lenses' &&
    product?.properties.isTrial &&
    TRIAL_DISABLED_COUNTRIES.includes(locale.country.toLowerCase());

  // for contact lenses we always want to enable the button
  const productInStock = product?.currentVariant?.availability.isAvailableOnline || isContactLens;

  function handleClick(props) {
    const {
      additionalProducts,
      polarised,
      premiumLenses,
      prescriptionType,
      selectedFilter,
      lenses_production_type,
      lens_upgrade_type
    } = props;

    if (hasError) {
      setIsModalOpen(true);
    } else if (product && productShouldBeConfigured) {
      if ((product.productType === 'contact_lenses' && locale.country.toLowerCase() === 'us') || isDisabledTrial) {
        setIsInvalidCountry(true);
      } else {
        setIsProductConfiguratorOpen(true);
      }
    } else {
      attemptAddToCart({
        additionalProducts,
        lenses_production_type,
        lens_upgrade_type,
        polarised,
        /** @deprecated */
        premiumLenses,
        prescriptionType,
        lensColor: selectedFilter
      });
    }
  }

  function toggleLensConfigurator(isOpen) {
    setIsProductConfiguratorOpen(isOpen);
    if (isOpen === false && lensType) {
      setLensType(null);
    }
  }

  useEffect(() => {
    const query = qs.parse(window.location.search);

    if (
      (query.configurator === 'daily-contacts' && sku.match(/daily-contacts/gi)) ||
      (query.configurator === 'daily-contacts-trial' && sku.match(/daily-contacts/gi)) ||
      (query.configurator === 'daily-contacts-toric' && sku.match(/daily-contacts/gi)) ||
      (query.configurator === 'daily-contacts-regular' && sku.match(/daily-contacts/gi)) ||
      (query.configurator === 'daily-contacts-trial-regular' && sku.match(/daily-contacts/gi)) ||
      (query.configurator === 'daily-contacts-trial-toric' && sku.match(/daily-contacts/gi))
    ) {
      if (query.configurator.includes('toric')) {
        setLensType('toric');
      } else if (query.configurator.includes('regular')) {
        setLensType('regular');
      }
      // preventing other components from opening after the first has processed the query
      // by deleting the query after whichever component has mounted first
      delete query.configurator;
      const url = new URL(window.location.href);
      url.search = qs.stringify(query);
      window.history.replaceState({}, document.title, url.toString());

      setIsProductConfiguratorOpen(true);
    }
  }, []);

  async function attemptAddToCart({
    additionalProducts = null,
    polarised = null,
    prescriptionType = null,
    /** @deprecated */
    premiumLenses = null,
    lensColor = null,
    lenses_production_type = null,
    lens_upgrade_type = null
  }): Promise<void> {
    // continue to the cart step inside the configurator
    const shouldRedirectToCart = product.productType !== 'frame';

    return await addToCart({
      additionalProducts,
      lenses_production_type,
      lens_upgrade_type,
      polarised,
      /** @deprecated */
      premiumLenses,
      prescriptionType,
      productType: product.productType,
      redirectToCart: shouldRedirectToCart,
      lensColor,
      variant: product.currentVariant
    });
  }

  return (
    <>
      <IncompatibleCountryModal
        onClose={() => setIsInvalidCountry(false)}
        isOpen={isInvalidCountry}
        type={isDisabledTrial ? 'contactLensTrial' : 'product'}
      />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data-testid={'product-configurator-plugin-error-modal'}
      >
        <Modal.Body>
          <Typography variant='bodyM'>
            <FormattedMessage {...messages.errorModalBody} />
          </Typography>
        </Modal.Body>
        <Modal.Buttons>
          <Button variant={buttonVariant} color={buttonColor} onClick={() => setIsModalOpen(false)}>
            <FormattedMessage {...messages.errorModalCta} />
          </Button>
        </Modal.Buttons>
      </Modal>
      {product && product.productType !== 'contact_lenses' && (
        <OnlineProductStock currentVariant={product.currentVariant} type='product' />
      )}
      <Button
        variant={buttonVariant}
        color={buttonColor}
        disabled={!productInStock || !product}
        onClick={handleClick}
        data-testid={`product-configurator-btn-${sku}`}
      >
        {children}
      </Button>
      {product && product.productType === 'frame' && (
        <ConfiguratorProvider>
          <FrameConfigurator variant={product.currentVariant} />
        </ConfiguratorProvider>
      )}
      {product &&
        !toricContactLensQuery.isValidating &&
        product.productType === 'contact_lenses' &&
        toricLensProduct?.productType === 'contact_lenses' && (
          <ContactLensConfigurator
            regularProduct={product}
            lensType={!toricLensProduct ? 'regular' : lensType}
            toricProduct={toricLensProduct}
            webStore={webStore}
            loading={promotionQuery.isValidating}
            promotions={promotionQuery.data?.data}
            isDrawerOpen={isProductConfiguratorOpen}
            toggleDrawer={toggleLensConfigurator}
          />
        )}
    </>
  );
}
